import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";

interface SquareFontAwesomeIconProps {
    icon: IconProp;
    size?: string;
    sx?: SxProps<Theme>;
}

export default function SquareFontAwesomeIcon(props: SquareFontAwesomeIconProps) {
    const { icon, size, sx = [] } = props;

    return <Box component={FontAwesomeIcon} icon={icon} sx={[
        { width: `${size ?? "1em"} !important`, height: size ?? "1em" },
        ...(Array.isArray(sx) ? sx : [sx])
    ]} />
}