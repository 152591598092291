
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import LandingPage from '../components/landing/LandingPage';
import LanguageAwareHead from '../components/LanguageAwareHead';
import Head from "next/head";

const ldContent = `{
  "@context": "https://schema.org",
  "@type": "WebApplication",
  "name": "Werewolf Online",
  "browserRequirements": "Requires JavaScript and HTML 5 support",
  "applicationCategory": "GameApplication",
  "applicationSubCategory": "Strategy Game",
  "offers": {
    "@type": "Offer",
    "price": "0"
  }
}`

export default function Home() {
  return <>
    <Head>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: ldContent }} />
    </Head>
    <LanguageAwareHead />
    <LandingPage landingPageType="create" />
  </>
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  