/* eslint-disable @next/next/no-img-element */
import { faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, Box, Skeleton, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { useEffect, useState } from "react";
import { BasePlayer } from "../../types/BasePlayer";

interface PlayerAvatarProps {
    player?: BasePlayer;
    height?: string;
    width?: string;
    sx?: SxProps<Theme>;
}

export default function PlayerAvatar(props: PlayerAvatarProps) {
    const { player, height, width, sx } = props;

    const [loaded, setLoaded] = useState<string | null>(null);
    const [imageLoading, setImageLoading] = useState(player ? !!player.picture : true);

    useEffect(() => {
        if (player && loaded !== player.picture) {
            setImageLoading(!!player.picture);
        }
    }, [setImageLoading, loaded, player]);

    return <Box sx={sx}>
        <Avatar sx={{ height: height || "60px", width: width || "60px", display: !player || imageLoading ? "none" : "flex" }}>
            {player?.picture ? <img src={player.picture} onLoad={() => {
                setLoaded(player.picture);
                setImageLoading(false);
            }} height={height || "60px"} width={width || "60px"} alt={player ? `Avatar of ${player.username}` : "Player avatar"} /> : <Box component={FontAwesomeIcon} icon={faUser} sx={{ width: `calc(${width || "60px"} / 2) !important`, height: `calc(${height || "60px"} / 2)` }} />}
        </Avatar>
        <Skeleton animation="wave" variant="circular" sx={{ display: player && !imageLoading ? "none" : "block" }}><Avatar sx={{ height: height || "60px", width: width || "60px" }} /></Skeleton>
    </Box>
}
