import useSWR, { KeyedMutator } from "swr";
import { BasePlayer } from "../types/BasePlayer";
import { SessionToken } from "../types/SessionToken";
import fetcher from "../utils/fetcher";

export default function usePlayerById(sessionToken: SessionToken, playerId: string | null | undefined | false): { player: BasePlayer, loading: boolean, error: any, mutate: KeyedMutator<any> } {
    const { data, error, mutate } = useSWR(sessionToken && playerId && [(process.env.apiUrl + '/player/'), sessionToken, playerId], (url, token, playerId) => fetcher(url + playerId, {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }));

    return {
        player: data,
        loading: !error && !data,
        error,
        mutate
    }
}