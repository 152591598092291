import { Theme, Tooltip } from '@mui/material';
import { ButtonUnstyled, ButtonUnstyledProps } from '@mui/base';
import { Box, SxProps } from '@mui/system';
import { LazyMotion, domAnimation, m } from "framer-motion";
import { forwardRef } from 'react';

const disabledButtonVariants = {
    whileTap: {
        x: [0, -2, 2, 0],
        transition: {
            duration: 0.1,
            times: [0, 0.35, 0.65, 1]
        },
        scale: 1
    },
    whileHover: {
        x: 0,
        scale: 1.03
    }
}

const enabledButtonVariants = {
    whileTap: {
        scale: 0.9
    },
    whileHover: {
        scale: 1.1
    }
}

interface ButtonProps extends ButtonUnstyledProps {
    tooltip?: string
    tooltipPlacement?: "bottom" | "left" | "right" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start"
    wrapperSx?: SxProps<Theme>
    sx?: SxProps<Theme>
}

const BoxMotionButton = forwardRef<any, any>(function BoxMotionButton(props: any, ref: any) {
    const { ownerState, ...others } = props;

    return <Box component={m.button} ref={ref} {...others} />
})

const Button = forwardRef<any, ButtonProps>(function Button(props: ButtonProps, ref: any) {
    const { disabled, onClick, tooltip, tooltipPlacement, wrapperSx = [], sx = [], ...others } = props;

    const buttonVariants = disabled ? disabledButtonVariants : enabledButtonVariants;

    const button = <ButtonUnstyled
        ref={ref}
        component={BoxMotionButton}
        variants={buttonVariants}
        whileHover={"whileHover"}
        whileTap={"whileTap"}
        onClick={onClick}
        disabled={disabled}
        sx={[
            {
                outline: "none",
                backgroundColor: "secondary.main",
                border: "none",
                borderRadius: "8px",
                color: "white",
                fontSize: "1em",
                fontWeight: "600",
                padding: { xs: "8px 16px", sm: "8px 56px" },
                cursor: "pointer",
                transform: "scale(1)",
                willChange: "transform"
            },
            disabled && { backgroundColor: "#969696" },
            tooltip !== undefined && disabled && { pointerEvents: "none" },
            ...(Array.isArray(sx) ? sx : [sx])
        ]}
        {...others}
    />

    return <LazyMotion features={domAnimation}>
        {tooltip ? <Tooltip title={tooltip} placement={tooltipPlacement}>
            <Box component="span" sx={[...(Array.isArray(wrapperSx) ? wrapperSx : [wrapperSx])]}>
                {button}
            </Box>
        </Tooltip> : button}
    </LazyMotion>
})

export default Button;